import 'slick-carousel';

$('.js-slider-image').slick({
	slidesToShow: 1,
	slidesToScroll: 1,
	arrows: false,
	dots: false,
	fade: true,
	autoplay: false,
	draggable: false,
	pauseOnFocus: false,
	pauseOnHover: false
});

var total = $('.hero .slider__slide').length;
var rand = Math.floor( Math.random() * total );
$('.js-slider-image').slick('slickGoTo', rand);

$('.js-slider-testimonial').slick({
	infinite: false,
	slidesToShow: 2,
	slidesToScroll: 1,
	arrows: true,
	dots: false,
	autoplay: false,
	draggable: false,
	pauseOnFocus: false,
	pauseOnHover: false,
	responsive: [
	{
		breakpoint: 767,
		settings: {
			slidesToShow: 1,
			slidesToScroll: 1
		}
	}]
});