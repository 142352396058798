$(window).on( 'load', function() {
	if ($('.home').length) {
		var winH = $(window).height() - $('.header').height();
	} else if($('.section-title').length) {
		var winH = $('.section-title').outerHeight() - $('.header').height();
	} else if($('.brand-intro').length) {
		$('.header').addClass('navyColor-lock');
	} else {
		var winH = 0;
		$('.header').addClass('navyColor-lock');
	}
	var $header = $('.header');

	if ($(this).scrollTop() > winH ) {
		$header.addClass('navyColor');
	} else {
		$header.removeClass('navyColor');
	}

	$( 'body' ).addClass( 'loaded' );
});

$(window).on('orientationchange, scroll', function () {
	if ($('.home').length) {
		var winH = $(window).height() - $('.header').height();
	} else if($('.section-title').length) {
		var winH = $('.section-title').outerHeight() - $('.header').height();
	} else if($('.brand-intro').length) {
		$('.header').addClass('navyColor-lock');
	} else {
		var winH = 0;
		$('.header').addClass('navyColor-lock');
	}
	var $header = $('.header');

	if ($(this).scrollTop() > winH ) {
		$header.addClass('navyColor');
	} else {
		$header.removeClass('navyColor');
	}
});

const winHeight = $(window).height();

$('section').each(function() {
	const $element = $(this);
	if ($element.offset().top <= winHeight) {
		$element.addClass('visible');
	}

	$(window).on('scroll', function() {
		if ($element.offset().top <= $(document).scrollTop() + winHeight) {
			$element.addClass('visible');
		}
	});
});

$.fn.random = function() {
    var randomIndex = Math.floor( Math.random() * this.length );
    return jQuery( this[ randomIndex ] );
};

for (var i = 0; i < 6; i++) {
	$('.section-logos .section__logos li.hidden').random().removeClass('hidden');
}


if ($('.section-contact').length) {
	$('body').addClass('contact--template');
}