$('.js--menu').on('click', function (e) {
	$(this)
		.toggleClass('is-menu-active');
	$('body')
		.toggleClass('is-menu-active');
	e.preventDefault();
});

if ( $(window).width() < 1023 ) {
	$('.footer--nav > ul > li > a').on('click', function (e) {
		if(!$(this).parent().hasClass('clicked_link')) {
			$('.nav li:has(ul)').removeClass('clicked_link');
			$(this)
				.parent()
				.addClass('clicked_link')
				.siblings()
				.removeClass('clicked_link');
			e.preventDefault();
		} else {
			return true;
		}
	});
}

$('.js-accordion .accordion__head h4').on('click', function(e) {
	let $this = $(this);
	let parent = $this.closest('.accordion__section');
	let tabsBody = parent.find('.accordion__body');

	if ( tabsBody.is(':visible') ) {
		$('.accordion__section').removeClass('is-active')
		$('.js-accordion .accordion__body').stop(true, true).hide(400);
	}

	if ( tabsBody.is(':hidden') ) {
		$('.js-accordion .accordion__body').stop(true, true).hide(400);
		$('.accordion__section').removeClass('is-active')
		tabsBody.stop(true, true).show(400);
		parent.addClass('is-active')
	}

	e.preventDefault();
});